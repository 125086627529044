import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import { ContainerItemStatuses } from "../../components/constants/enums/ContainerItemStatuses";
import { BasicModal } from "../../components/Modals/BasicModal";
import { fetchFullStagingBuilder, Istate } from "../../redux";
import { resetBuilderInventory } from "../../redux/inventory/inventoryActions";
import { clearActiveStagingBuilder } from "../../redux/stagingBuilder/actions/clearActiveStagingBuilder";
import { ContainerItem } from "../../redux/stagingBuilder/stagingBuilderTypes";
interface Props {
  item: itemVerbose | (ContainerItem & { itemNo: string });
  children: (handleClick: () => void) => React.ReactNode;
}
interface ActivityShape {
  containerItems: Array<Partial<ContainerItem> & { stagingBuilderID: number }>;
  holdItems: HoldItem[];
  stagingItems: StagingItem[];
}
/**
 * Shows the StagingBuilders that this specific item has been in
 * @todo
 * 1) clean up containerItems
 */
export const HandleItemActivityClick = ({ item, children }: Props) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const socket = useSelector(
    (state: Istate) => state.data.socket,
    shallowEqual
  );
  const handleClick = () => {
    if (socket) {
      socket.emit("IVENTORY_ACTIVITY", JSON.stringify({ itemID: item.itemID }));
    }
  };
  const [unused, setUnused] = useState(false);
  const [tot, setTot] = useState({ stagings: 0, stagingBuilders: 0 });
  const handleResponse = (stringyData: string) => {
    const data: ActivityShape = JSON.parse(stringyData);
    let builders: number[] = [];
    data.containerItems = Array.from(
      new Set(
        data.containerItems.map((item) => {
          if (
            item.itemStatus !== ContainerItemStatuses.HOLD &&
            item.itemStatus !== ContainerItemStatuses.STAGED
          )
            return {
              stagingBuilderID: item.stagingBuilderID,
              fileNumber: (item as any)?.fileNumber || null,
            };
          return undefined;
        })
      )
    ).filter((item) => item) as ActivityShape["containerItems"];
    data.stagingItems = data.stagingItems.filter((item, index) => {
      if (
        Array.from(new Set([item.stagingBuilderID, ...builders])).length !==
        builders.length
      ) {
        builders.push(item.stagingBuilderID);
        return true;
      }
      return false;
    });

    builders = [];
    data.holdItems = data.holdItems.filter((item) => {
      builders.push(item.stagingBuilderID);
      if (Array.from(new Set(builders)).length === builders.length) return true;
      return false;
    });
    if (data.holdItems.length + data.stagingItems.length === 0) setUnused(true);
    setActivity(data);
    setOpen(true);
    setTot({
      stagingBuilders: data.containerItems.length,
      stagings: data.stagingItems.length,
    });
  };
  const [activity, setActivity] = useState<ActivityShape>();
  useEffect(() => {
    socket?.on("INVENTORY_ACTIVITY_RESPONSE", handleResponse);
    return () => {
      socket?.off("INVENTORY_ACTIVITY_RESPONSE");
    };
  }, [socket]);
  const stagingBuilderSelected = (
    stagingBuilderID: number,
    doubleClicked: boolean = false
  ) => {
    dispatch(push("/"));
    dispatch(clearActiveStagingBuilder());
    dispatch(resetBuilderInventory());
    dispatch(fetchFullStagingBuilder(stagingBuilderID, true, false));
    // setTimeout(() => {
    // }, 100);
  };
  console.log(activity?.stagingItems);
  return (
    <>
      {children(handleClick)}
      <BasicModal
        open={open}
        setOpen={() => {
          setOpen(false);
        }}
      >
        <div className="grid col-2 overflow-hidden text-xl">
          <h1 className="text-4xl full-col-span">Item - {item.itemNo}</h1>
          <div className="overflow-y-scroll">
            <h2 className="text-3xl mt-4">StagingBuilders: </h2>
            {unused && <>No Staging Proposals Currently</>}
            <div>
              {activity?.containerItems?.map((containerItem, index) => (
                <>
                  {!index && (
                    <h3 className="text-2xl mt-3">All StagingBuilders</h3>
                  )}
                  <button
                    className="table-row"
                    onClick={() =>
                      stagingBuilderSelected(containerItem.stagingBuilderID)
                    }
                    // to="/stage"
                  >
                    StagingBuilder - {containerItem.stagingBuilderID}{" "}
                    {(containerItem as any)?.fileNumber
                      ? `(HS - ${(containerItem as any)?.fileNumber})`
                      : ""}
                  </button>
                </>
              ))}
            </div>
            <div>
              {activity?.holdItems?.map((stagingItem, index) => (
                <>
                  {!index && <h3 className="text-2xl mt-3">Active Hold</h3>}
                  <button
                    className="table-row"
                    onClick={() =>
                      stagingBuilderSelected(stagingItem.stagingBuilderID)
                    }
                    // to="/stage"
                  >
                    StagingBuilder - {stagingItem.stagingBuilderID}{" "}
                    {(stagingItem as any)?.fileNumber
                      ? `(HS - ${(stagingItem as any)?.fileNumber})`
                      : ""}
                  </button>
                </>
              ))}
            </div>
            <div>
              {activity?.stagingItems
                ?.filter((stagingItem) => stagingItem.stagedEnd === null)
                .map((stagingItem, index) => (
                  <>
                    {!index && <h3 className="text-2xl mt-3">Active Staged</h3>}
                    <button
                      className="table-row"
                      onClick={() =>
                        stagingBuilderSelected(stagingItem.stagingBuilderID)
                      }
                      // to="/stage"
                    >
                      StagingBuilder - {stagingItem.stagingBuilderID}{" "}
                      {(stagingItem as any)?.fileNumber
                        ? `(HS - ${(stagingItem as any)?.fileNumber})`
                        : ""}
                    </button>
                  </>
                ))}
            </div>
            <div>
              {activity?.stagingItems
                ?.filter((stagingItem) => stagingItem.stagedEnd !== null)
                .map((stagingItem, index) => (
                  <>
                    {!index && (
                      <h3 className="text-2xl mt-3">Previously Staged</h3>
                    )}
                    <button
                      className="table-row"
                      onClick={() =>
                        stagingBuilderSelected(stagingItem.stagingBuilderID)
                      }
                      // to="/stage"
                    >
                      StagingBuilder - {stagingItem.stagingBuilderID}{" "}
                      {(stagingItem as any)?.fileNumber
                        ? `(HS - ${(stagingItem as any)?.fileNumber})`
                        : ""}
                    </button>
                  </>
                ))}
            </div>
          </div>
          <div>
            <h2 className="text-3xl mt-4">Item Stats:</h2>
            <div className="grid col-2-auto">
              <span className="text-bold">Total Stagings:</span>
              <span>{tot.stagings}</span>
              <span className="text-bold">Total StagingBuilders:</span>
              <span>{tot.stagingBuilders}</span>
              <span className="text-bold">Total Favorited:</span>
              <span></span>
              <span className="text-bold">Total Days Rented:</span>
              <span></span>
            </div>
          </div>
        </div>
      </BasicModal>
    </>
  );
};
